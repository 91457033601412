<template>
  <LazyCognito v-if="initalizeCognito" />
</template>

<script>
import { mapState } from 'pinia'
import { getAuthPaths, AuthPages } from '@layers/web/utils/auth/auth'
import events from '@layers/web/constants/events.js'

export default defineNuxtComponent({
  setup () {
    const { urls: localeURLs } = useLocale()

    return {
      localeURLs,
    }
  },

  data () {
    return {
      initalizeCognito: false,
    }
  },

  computed: {
    ...mapState(useLocaleStore, ['locale']),
    ...mapState(useUserStore, ['isLoggedIn']),
  },

  mounted () {
    this.$event.$on(events.loginWithAction, this.initCognito)
  },

  beforeUnmount () {
    this.$event.$off(events.loginWithAction, this.initCognito)
  },

  methods: {
    initCognito () {
      this.initalizeCognito = true
      if (!this.isLoggedIn) {
        const registerUrl = getAuthPaths(this.locale)[AuthPages.signup]
        this.$router.push(`${this.localeURLs.auth}/${registerUrl}`)
      }
    },
  },
})
</script>
